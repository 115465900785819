<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/img/header.jpg')"
      />
      <div class="content-center">
        <div class="container">
          <TypingTitle :titles="['Our Portfolio']" />
        </div>
      </div>
    </div>
    <div
      data-background-color="seus-green"
      class="container-fluid"
    >
      <div class="container">
        <card>
          <div class="section-header">
            <h5 class="portfolio-page">
              Clients
            </h5>
            <p class="clients">
              Our client portfolio includes those to whom we have provided our services, others
              with on-going projects and yet others who have engaged us for their upcoming
              proposed projects. The abridged listing is outlined below
            </p>
          </div>
        </card>
        <card>
          <div class="table-responsive-md">
            <table class="table table-hover table">
              <thead>
                <tr class="clients2">
                  <th
                    class="clients2"
                    scope="col"
                  >
                    #
                  </th>
                  <th
                    class="clients2"
                    scope="col"
                  >
                    CONSULTANCY DESCRIPTION
                  </th>
                  <th
                    class="clients2"
                    scope="col"
                  >
                    PROJECT TYPE
                  </th>
                  <th
                    class="clients2"
                    scope="col"
                  >
                    LOCATION
                  </th>
                  <th
                    scope="col"
                    class="clients2"
                  >
                    YEAR
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    1
                  </th>
                  <td>Environmental and Social Impact Assessment for the establishment of a wine and kombucha manufacturing plant in Lusaka by Appollo Products Ltd</td>
                  <td>Manufacturing</td>
                  <td>Lusaka, Zambia</td>
                  <td>2024</td>
                </tr>
                <tr>
                  <th scope="row">
                    2
                  </th>
                  <td>Environmental and Social Impact Assessment for the establishment of an electrical cables manufacturing plant in Lusaka by Huasheng Science and Technology Company Ltd</td>
                  <td>Manufacturing</td>
                  <td>Chibombo, Zambia</td>
                  <td>2024</td>
                </tr>
                <tr>
                  <th scope="row">
                    3
                  </th>
                  <td>Environmental and Social Impact Assessment for the establishment of a lime manufacturing plant in Lusaka by Zamcolourful Investments Ltd</td>
                  <td>Manufacturing</td>
                  <td>Lusaka, Zambia</td>
                  <td>2024</td>
                </tr>
                <tr>
                  <th scope="row">
                    4
                  </th>
                  <td>Independent review and assessment of the impact of graphite mining on Lilongwe Water Board’s raw water source at Malingunde in Malawi</td>
                  <td>Construction</td>
                  <td>Lilongwe, Malawi</td>
                  <td>2023</td>
                </tr>
                <tr>
                  <th scope="row">
                    5
                  </th>
                  <td>Environmental and Social Compliance - Construction works for the rehabilitation and widening of the M1 Road (Lot 1) from Kamuzu International Airport (KIA) turn-off to Kasungu in Malawi</td>
                  <td>Construction</td>
                  <td>Lilongwe, Malawi</td>
                  <td>2023</td>
                </tr>
                <tr>
                  <th scope="row">
                    6
                  </th>
                  <td>Environmental and Social Impact Assessment (EIA) for the proposed 25 MW solar power plant at Lusaka South Multi-Facility Economic Zone by LUSANJE Solar Company</td>
                  <td>Manufacturing</td>
                  <td>Lusaka, Zambia</td>
                  <td>2023</td>
                </tr>
                <tr>
                  <th scope="row">
                    7
                  </th>
                  <td>Environmental and Social Impact Assessment (ESIA) for the establishment of a proposed 50 MW solar plant in Masaiti, Ndola by CVVP Solar Ltd</td>
                  <td>Construction</td>
                  <td>Ndola, Masaiti, Zambia</td>
                  <td>2023</td>
                </tr>
                <tr>
                  <th scope="row">
                    8
                  </th>
                  <td>Environmental and Social Impact Assessment (ESIA) for exploration activities in Rufunsa by Omni Contractors Ltd</td>
                  <td>Mining</td>
                  <td>Rufunsa, Zambia</td>
                  <td>2023</td>
                </tr>
                <tr>
                  <th scope="row">
                    9
                  </th>
                  <td>Environmental and Social Impact Assessment (EIA) for the establishment of a block-making factory in Kalulushi by Blocmac Ltd</td>
                  <td>Construction</td>
                  <td>Kalulushi, Zambia</td>
                  <td>2023</td>
                </tr>
                <tr>
                  <th scope="row">
                    10
                  </th>
                  <td>Environmental and Social Impact Assessment for a proposed service station by Oryx Fuels in Lusaka</td>
                  <td>Construction</td>
                  <td>Lusaka, Zambia</td>
                  <td>2023</td>
                </tr>
                <tr>
                  <th scope="row">
                    11
                  </th>
                  <td>Environmental & Social Impact Assessment (EIA) – Solwezi water supply - RVO / RHDHV / NWWSSCL for the supply of water to Solwezi District</td>
                  <td>Construction / Water Supply</td>
                  <td>Solwezi, Zambia</td>
                  <td>2020</td>
                </tr>
                <tr>
                  <th scope="row">
                    12
                  </th>
                  <td>Environmental & Social Impact Assessment (EIA) – Establishment of integrated agriculture project in Chibombo by SABLE Transport</td>
                  <td>Agriculture / Construction</td>
                  <td>Lusaka, Zambia</td>
                  <td>2020</td>
                </tr>
                <tr>
                  <th scope="row">
                    13
                  </th>
                  <td>Environmental & Social Impact Assessment (ESIA) – Establishment and operation of a PVC pipe manufacturing plant by Etone C.M</td>
                  <td>Manufacturing</td>
                  <td>Lusaka, Zambia</td>
                  <td>2020</td>
                </tr>
                <tr>
                  <th scope="row">
                    14
                  </th>
                  <td>Environmental Project Brief (EPB) – Setting up of a fuel station on Kafue Road, Shimabala by ENROD (Z) Ltd</td>
                  <td>Energy / Construction</td>
                  <td>Kafue, Zambia</td>
                  <td>2019</td>
                </tr>
                <tr>
                  <th scope="row">
                    15
                  </th>
                  <td>Environmental Project Brief (EPB) – Establishment of milling plants in Mbala and Luashya by ZCF / Etone Group</td>
                  <td>Construction</td>
                  <td>Lusaka, Zambia</td>
                  <td>2019</td>
                </tr>
                <tr>
                  <th scope="row">
                    16
                  </th>
                  <td>Environmental & Social Impact Assessment (EIA) – Upgrading of 110 km of the Great North Road from Kapiri Mposhi to Ndola by RDA/GRZ/CJIC</td>
                  <td>Project Construction</td>
                  <td>Ndola, Zambia</td>
                  <td>2018</td>
                </tr>
                <tr>
                  <th scope="row">
                    17
                  </th>
                  <td>Environmental and Feasibility Study for the Rehabilitation of the Urban Water Supply by Lusaka Water & Sewerage Company / CRIDF</td>
                  <td>Water / Construction</td>
                  <td>Ndola, Zambia</td>
                  <td>2018</td>
                </tr>
                <tr>
                  <th scope="row">
                    18
                  </th>
                  <td>Environmental Project Brief (EPB) – Setting up of a fuel storage facility for the Kazungula Bridge by PES Oil Ltd</td>
                  <td>Construction</td>
                  <td>Kazungula, Zambia</td>
                  <td>2018</td>
                </tr>
                <tr>
                  <th scope="row">
                    19
                  </th>
                  <td>Environmental Project Brief (EPB) – Setting up of a fuel service station for an asphalt plant and quarry by SABLE Transport & Construction</td>
                  <td>Project Management</td>
                  <td>Lusaka, Zambia</td>
                  <td>2016</td>
                </tr>
                <tr>
                  <th scope="row">
                    20
                  </th>
                  <td>Environmental Project Brief (EPB) – Setting up of an asphalt plant and quarry by SABLE Transport & Construction</td>
                  <td>Mining / Road Construction</td>
                  <td>Kabwe, Zambia</td>
                  <td>2015</td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../components/Cards/Card";
import TypingTitle from '@/components/TypingTitle';
export default {
  name: 'Portfolio',
  bodyClass: 'portfolio-page',
  components: {
    Card,
    TypingTitle
  }
};
</script>