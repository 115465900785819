<template>
  <h3 class="title" ref="dynamicTitle"></h3>
</template>

<script>
export default {
  name: 'TypingTitle',
  props: {
    titles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  mounted() {
    this.startTypingEffect();
  },
  methods: {
    typeText(text, callback) {
      const titleElement = this.$refs.dynamicTitle;
      titleElement.innerHTML = '';
      titleElement.style.animation = `typing ${text.length / 10}s steps(${text.length}, end)`;
      let charIndex = 0;

      const type = () => {
        if (charIndex < text.length) {
          titleElement.innerHTML += text[charIndex];
          charIndex++;
          setTimeout(type, 100);
        } else if (callback) {
          setTimeout(callback, 2000);
        }
      };
      type();
    },
    startTypingEffect() {
      this.typeText(this.titles[this.currentIndex], () => {
        this.currentIndex = (this.currentIndex + 1) % this.titles.length;
        this.startTypingEffect();
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 2em;
  white-space: nowrap;
  overflow: hidden;

  animation: blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: black; }
}
</style>
